import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import FeaturedCollectionLoading from '../../../skeleton/FeaturedCollectionLoading';

const BlurHashImage = dynamic(() => import('../../../BlurHashImage'), {
  loading: () => null,
  ssr: false
});

function FeaturedCollection({
  title,
  img,
  blurhash,
  videoCount,
  background = 'bg-n-700',
  description,
  buttonStyle = 'cs-button',
  button,
  titleStyle = 'text-white-90',
  countStyle = 'text-white-70',
  descriptionStyle = 'text-white-90',
  href,
  showCta,
  instanceData
}) {
  const {
    state: { currentUser }
  } = useApp();
  const router = useRouter();
  const { trackEvent } = useMatomo();
  const { showImage = true } = showCta;

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    if (href) router.push(href);
  };
  return (
    <section className="featured-collection">
      <div className="container-rnp">
        <div className={`featured-collection-wrapper ${background}`}>
          {showImage && img && (
            <div className="featured-collection-img relative">
              <BlurHashImage
                src={img}
                blurhash={blurhash}
                fill
                alt={title}
                className="cover"
              />
            </div>
          )}

          <div className="featured-collection-dec">
            {title && <div className={`title ${titleStyle}`}>{title}</div>}
            {videoCount !== undefined && (
              <div className={`featured-collection-video-count ${countStyle}`}>
                {videoCount} Items
              </div>
            )}

            {description && <p className={descriptionStyle}>{description}</p>}

            <Button
              onClick={() =>
                handleAnalytics(
                  MATOMO_CATEGORY.COLLECTION,
                  MATOMO_ACTION.FEATURED_COLLECTION_BTN_CLICKED
                )
              }
              size="large"
              type="primary"
              className={`cs-button ${buttonStyle}`}
            >
              {button}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturedCollection.Loading = function Loading() {
  return <FeaturedCollectionLoading />;
};

export default FeaturedCollection;
