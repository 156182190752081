import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import ActionButton from '../../../ActionButton';
import BlurHashImage from '../../../BlurHashImage';
import CtaBannerLoading from '../../../skeleton/CtaBannerLoading';

function CtaBanner({
  background = 'bg-transparent',
  ctaBackgroundImage,
  blurhash,
  title,
  descriptions,
  ctaBackgroundColor,
  ctaBackgroundDark,
  primaryAction
}) {
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        title,
        email: currentUser?.email ?? ''
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        title,
        email: currentUser?.email ?? ''
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <section className={`ctaBanner-section ${background}`}>
      <div className="container-rnp">
        <div className="container-marketing">
          <div
            className={`cta-bnnner-block ${
              // eslint-disable-next-line no-nested-ternary
              ctaBackgroundImage
                ? 'ctabannerbackground'
                : (ctaBackgroundColor &&
                    `${ctaBackgroundColor} ctabannerbackgroundcolor`) ||
                  'ctabannerbackground-none'
            }`}
          >
            {ctaBackgroundImage && (
              <BlurHashImage
                src={ctaBackgroundImage}
                blurhash={blurhash}
                fill
                alt={title}
                className="cover"
              />
            )}

            <div className="cta-content">
              <div
                className={`title ${
                  ctaBackgroundDark ? 'text-white-90' : 'text-black-90'
                }`}
              >
                {title}
              </div>
              <p
                className={`description ${
                  ctaBackgroundDark ? 'text-white-80' : 'text-black-80'
                }`}
              >
                {descriptions}
              </p>
              {primaryAction?.title && (
                <ActionButton
                  size="large"
                  className="m-auto"
                  primaryBtn={ctaBackgroundDark}
                  secondaryBtn={!ctaBackgroundDark}
                  type={primaryAction?.type}
                  internalPageType={primaryAction?.internalPageType}
                  url={primaryAction?.url}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_BANNER_PRIMARY_BTN_CLICKED
                    )
                  }
                >
                  {primaryAction?.title}
                </ActionButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

CtaBanner.Loading = function Loading() {
  return <CtaBannerLoading />;
};

export default CtaBanner;
