import { Button, Typography } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { X } from 'phosphor-react';
import { useMemo } from 'react';
import {
  PAGE_GROUPS,
  ROUTES,
  SUBSCRIPTION_PLAN_KEYS
} from '../../../../common/constants';
import usePageGroup from '../../../../common/usePageGroup';
import { encode } from '../../../../common/utils';
import { useApp } from '../../../../providers/AppContext';
import SubscriptionBlockLoading from '../../../skeleton/SubscriptionBlockLoading';

const { Text, Title } = Typography;
function SubscriptionBlock({
  primaryAction,
  thumb,
  title = `You're using a limited version.`,
  description = 'Join the Insider Club and Unlock the Full Potential',
  btnLabel = 'SUBSCRIBE NOW',
  modal,
  setShowSubscribeModal,
  previewVideo = '',
  loginText
}) {
  const {
    state: { currentUser, loading, isAuthenticated }
  } = useApp();

  const router = useRouter();
  const pathname = usePathname();

  const { getRandomPath } = usePageGroup();
  const hasActivePlan =
    currentUser &&
    ![SUBSCRIPTION_PLAN_KEYS.FREE, SUBSCRIPTION_PLAN_KEYS.GUEST].includes(
      currentUser?.currentPlan
    );

  const hasActiveHeavyWeightPlan =
    hasActivePlan &&
    [
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_ANNUALLY,
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_MONTHLY
    ].includes(currentUser?.currentPlan);

  const buttonText = useMemo(() => {
    if (loading) return '';
    if (hasActiveHeavyWeightPlan) return `CHANGE SUBSCRIPTION`;
    if (hasActivePlan) return `UPGRADE NOW`;
    return btnLabel;
  }, [hasActiveHeavyWeightPlan, hasActivePlan, loading, btnLabel]);

  const handleRedirect = () => {
    const path = getRandomPath(PAGE_GROUPS.SUBSCRIPTION);
    if (path)
      router.push({
        pathname: path,
        query: {
          redirectPath: pathname
        }
      });
  };

  return (
    <section
      className={`subscription-block-wrapper ${
        modal ? 'modal-subscription' : ''
      }`}
    >
      <div className="container">
        <div className="subscription-block">
          <div className="sb-block">
            <Image src={thumb} fill alt="" />
          </div>
          <div className="sb-content text-heading">
            <Button
              className="sb-cancel"
              type="text"
              onClick={() => {
                setShowSubscribeModal(false);
              }}
            >
              <X size={26} />
            </Button>
            <Text type="secondary">{title}</Text>
            <Title level={5}>{description}</Title>
            {primaryAction && (
              <Button
                loading={loading}
                size="small"
                type="primary"
                onClick={handleRedirect}
              >
                {buttonText}
              </Button>
            )}
            {!isAuthenticated && (
              <div>
                <Text type="secondary">{loginText}</Text> &nbsp;
                <Link
                  href={{
                    pathname: ROUTES.LOGIN,
                    query: {
                      q: encode(pathname)
                    }
                  }}
                >
                  Login
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

SubscriptionBlock.Loading = function Loading() {
  return <SubscriptionBlockLoading />;
};

export default SubscriptionBlock;
