import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Badge,
  Button,
  ConfigProvider,
  Dropdown,
  Slider,
  Typography
} from 'antd';
import { isArray } from 'lodash';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { DotsThreeOutlineVertical } from 'phosphor-react';
import React, { Fragment } from 'react';
import {
  COLOR_KEYS,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  ROUTES,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';

const BlurHashImage = dynamic(() => import('../../../BlurHashImage'), {
  loading: () => null,
  ssr: false
});

const { Text, Paragraph } = Typography;

function PodcastCard({
  src,
  blurhash,
  defaultValue,
  time,
  topics,
  collection,
  title,
  date,
  isList,
  tagTextColor = 'text-n-900',
  tags,
  podcastSelect = false,
  podcastDescription,
  dropdownItems,
  href = '#',
  instanceData,
  instanceId,
  instanceType
}) {
  const {
    state: { placeholderImage, currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();

  const handlePodcastAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData,
        instanceId,
        instanceType
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData,
        instanceId,
        instanceType
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <div className={`video-card ${isList && 'video-list'}`}>
      <div
        className="video-image relative bg-n-600"
        onClick={() =>
          handlePodcastAnalytics(
            MATOMO_CATEGORY.VIEW_DETAIL,
            MATOMO_ACTION.VIEW_PODCAST
          )
        }
      >
        <Link prefetch={false} href={href}>
          <BlurHashImage
            src={src || placeholderImage}
            blurhash={blurhash}
            fill
            alt={title}
            className="image-thumbnail cover"
          />
        </Link>
        {time ? <div className="timestemp">{time} </div> : null}
        {defaultValue ? (
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  railSize: 4,
                  borderRadiusXS: 12,
                  colorBgElevated: ' #FFFFFF',
                  dotSize: 8,
                  handleSize: 16,
                  handleSizeHover: 16,
                  colorPrimaryBorder: '#FE4819',
                  colorBorderSecondary: '#B0ADAB',
                  colorBgContainer: '#B0ADAB',
                  colorTextDescription: '#FFFFFF',
                  colorTextDisabled: '#FFFFFF',
                  colorFillContentHover: '#FFFFFF',
                  colorFillSecondary: '#ffff',
                  colorFillTertiary: '#B0ADAB',
                  colorPrimaryBorderHover: '#3D3B38',
                  handleLineWidthHover: 0,
                  handleLineWidth: 0
                }
              }
            }}
          >
            <Slider defaultValue={defaultValue} />
          </ConfigProvider>
        ) : null}
      </div>
      <div className="vc-description">
        <div className="topics-wrapper">
          {topics?.length > 0
            ? topics
                ?.slice(0, 1)
                ?.map(({ name, id, primaryColor: tagBackground, slug }) => (
                  <Link
                    key={id}
                    prefetch={false}
                    className="category-badge"
                    href={`${ROUTES?.PODCASTS}/${slug}`}
                    // onClick={() => handleTopicsAnalytics(category)}
                  >
                    <Badge
                      className={`badge-xs text-black-90 ${
                        COLOR_KEYS[tagBackground]?.backgroundClass ?? ''
                      } ${tagTextColor}`}
                    >
                      {name}
                    </Badge>
                  </Link>
                ))
            : null}
        </div>
        {collection ? (
          <Text className="text-small-kicker text-black-80 block">
            {collection}
          </Text>
        ) : null}
        {title ? (
          <Link
            prefetch={false}
            href={href}
            className={`${
              podcastSelect ? 'text-lg-bold' : 'text-md-bold'
            } block vc-title`}
          >
            {title}
          </Link>
        ) : null}
        {isList && podcastDescription ? (
          <Paragraph
            className="text-small text-black-90 vc-paragraph"
            ellipsis={{ rows: 2 }}
          >
            {podcastDescription}
          </Paragraph>
        ) : null}
        {tags && !!tags?.length ? (
          <div className="tag-list ">
            {tags?.map(({ name, slug }, index) => (
              <Fragment key={slug}>
                <Link
                  prefetch={false}
                  className="text-small-bold text-black-80"
                  href={`${ROUTES.TAGS}/${slug}`}
                  // onClick={() => handleTagAnalytics(name)}
                >
                  {name}
                </Link>
                {index !== tags?.length - 1 && ', '}
              </Fragment>
            ))}
          </div>
        ) : null}

        {date ? (
          <Text className="text-small text-black-90 block ">{date}</Text>
        ) : null}
      </div>

      {isArray(dropdownItems) && dropdownItems?.length > 0 && (
        <Dropdown
          menu={{
            items: dropdownItems
            // onClick: ({ key }) => handleDropdownItemClick(key, videoId)
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            className="p-0 bg-transparent"
            type="text"
            onClick={(e) => e.preventDefault()}
          >
            <DotsThreeOutlineVertical size={24} />
          </Button>
        </Dropdown>
      )}
    </div>
  );
}

export default PodcastCard;
