import { useLazyQuery, useMutation } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Form, Input, Tooltip, Typography } from 'antd';
import { upperCase } from 'lodash';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Chats, ShareNetwork, X } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MODULE_TYPES,
  PAGE_TYPE,
  TRACK_VALUE,
  VIDEO_FROM_TYPES
} from '../../../../common/constants';
import { decode } from '../../../../common/utils';
import { CREATE_QUESTION } from '../../../../graphql/Mutations';
import { PLAYLIST_NEXT_VIDEO } from '../../../../graphql/Queries';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import PodcastInfoLoading from '../../../skeleton/PodcastInfoLoading';
import CustomPodcastPlayer from '../customPodcastPlayer';
import ModalComponent from '../customPodcastPlayer/ModalComponent';
import ShareSheet from '../customPodcastPlayer/ShareSheet';

const { TextArea } = Input;
const { Text } = Typography;

function PodcastInfo({
  title,
  description,
  videos = [],
  audios = [],
  videosWatchTimes,
  moduleType,
  podcastId,
  collection,
  podcastAsk = false,
  podcastShare = false,
  status,
  requiredPlan,
  isPremium
}) {
  const {
    state: { currentUser }
  } = useApp();
  const router = useRouter();
  const [form] = Form.useForm();
  const { data: session } = useSession();
  const [visible, setVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [isAskModalOpen, setIsAskModalOpen] = useState(false);
  const category = MATOMO_CATEGORY.PODCAST;
  const action = MATOMO_ACTION.ASK_QUESTION_BTN_CLICKED;
  const { query: { next } = {} } = router;
  const { trackEvent } = useMatomo();

  const [nextVideoData, setNextVideoData] = useState(null);

  const token = session?.accessToken;

  const queryConfigs = {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        token
      }
    }
  };

  const [createQuestion, { loading }] = useMutation(CREATE_QUESTION, {
    onError() {
      trackEvent({
        category,
        action,
        name: JSON.stringify({
          action,
          email: currentUser?.email ?? '',
          title,
          moduleType,
          podcastId,
          collection,
          status
        }),
        value: TRACK_VALUE.FAIL
      });
      gaEvent({
        category,
        action,
        name: JSON.stringify({
          action,
          email: currentUser?.email ?? '',
          title,
          moduleType,
          podcastId,
          collection,
          status
        }),
        value: TRACK_VALUE.FAIL
      });
    }
  });

  const [getPlaylistNextVideo] = useLazyQuery(
    PLAYLIST_NEXT_VIDEO,
    queryConfigs
  );

  useEffect(() => {
    if (next && token) {
      (async () => {
        try {
          const { type, order, slug } = decode(next);
          const defaultProps = {
            slug,
            type
          };
          switch (type) {
            case VIDEO_FROM_TYPES.PLAYLIST:
              {
                const { data, errors } = await getPlaylistNextVideo({
                  variables: {
                    order,
                    slug
                  }
                });
                if (data && !errors && data?.playlistNextVideo) {
                  const { slug: nextSlug, order: nextOrder } =
                    data?.playlistNextVideo;
                  setNextVideoData({
                    ...defaultProps,
                    videoSlug: nextSlug,
                    order: nextOrder
                  });
                }
              }
              break;
            default:
              break;
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      })();
    }
  }, [next, token, getPlaylistNextVideo]);

  const openAsk = () => {
    setIsAskModalOpen(true);
  };
  const handleSubmit = (values) => {
    const payload = {
      ...values,
      instanceId: podcastId,
      instanceType: PAGE_TYPE.PODCAST
    };
    createQuestion({
      variables: {
        data: payload
      },
      context: {
        headers: {
          token
        }
      }
    }).then(() => {
      form.resetFields();
      setIsAskModalOpen(false);
      trackEvent({
        category,
        action,
        name: JSON.stringify({
          action,
          email: currentUser?.email ?? '',
          title,
          moduleType,
          podcastId,
          collection,
          status
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category,
        action,
        name: JSON.stringify({
          action,
          email: currentUser?.email ?? '',
          title,
          moduleType,
          podcastId,
          collection,
          status
        }),
        value: TRACK_VALUE.SUCCESS
      });
    });
  };
  const handleAskModalCancel = () => {
    setIsAskModalOpen(false);
  };

  useEffect(() => {
    setShareUrl(window?.location?.href);
  }, []);

  const shareHandler = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <ModalComponent
        className="info-modal ask-question-modal"
        title="Ask a question directly to the podcast host!"
        closeIcon={<X size={24} />}
        open={isAskModalOpen}
        onCancel={handleAskModalCancel}
        footer={
          <div className="w-full">
            <Button
              key="submit"
              className="cs-button"
              type="primary"
              onClick={() => form?.submit()}
              block
              loading={loading}
            >
              Send question
            </Button>
          </div>
        }
      >
        <div className="form-group">
          <Form
            form={form}
            initialValues={{
              question: ''
            }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <div className="form-group">
              <Form.Item
                name="question"
                label="Question"
                rules={[
                  { required: true, message: 'Please input your question!' }
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </ModalComponent>
      <ModalComponent
        className="share-popup"
        title="Share"
        open={visible}
        onCancel={handleCancel}
        footer={false}
        mask={false}
      >
        <ShareSheet shareUrl={shareUrl} videos={videos} />
      </ModalComponent>
      <section
        className={`video-info-section ${
          moduleType === MODULE_TYPES.PODCAST_PLAYER && 'podcast-info-section'
        }`}
      >
        <CustomPodcastPlayer
          nextVideo={nextVideoData}
          VIDEOS={videos}
          watchTime={videosWatchTimes?.[videos?.[0]?.id] || 0}
          moduleType={moduleType}
          audios={audios}
          status={status}
          requiredPlan={requiredPlan}
          isPremium={isPremium}
        />
        <div className="container">
          <div className="video-info-wrapper">
            <div className="video-button-wrapper">
              {podcastAsk ? (
                <Tooltip placement="top" title="Start a Question">
                  <Button
                    className="videoaction"
                    type="primary"
                    icon={<Chats size={24} weight="bold" />}
                    onClick={openAsk}
                  >
                    Ask
                  </Button>
                </Tooltip>
              ) : (
                <div />
              )}
              {podcastShare && (
                <Button
                  className="videoaction"
                  type="primary"
                  icon={<ShareNetwork size={24} weight="bold" />}
                  onClick={shareHandler}
                >
                  Share
                </Button>
              )}
            </div>
            <div className="videoinfo-title">
              {collection !== undefined ? (
                <Text className="text-small text-black-90 mb-4 block video-count">
                  {upperCase(collection)}
                </Text>
              ) : null}
              {title ? <div className="display-h4">{title}</div> : null}
            </div>
            {description ? (
              <p className="paragraph text-black-70 w-full">{description}</p>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}

PodcastInfo.Loading = function Loading() {
  return <PodcastInfoLoading />;
};

export default PodcastInfo;
