import { useMatomo } from '@datapunt/matomo-tracker-react';
import Link from 'next/link';
import {
  COLOR_KEYS,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CategoryCardLoading from '../../../skeleton/CategoryCardLoading';

function CategoryCard({
  href,
  grid,
  landing,
  topic,
  backgroundColor,
  textColor = 'text-n-1000',
  instanceData
}) {
  const {
    state: { currentUser }
  } = useApp();

  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <Link
      prefetch={false}
      className={`category-card ${
        COLOR_KEYS[backgroundColor]?.backgroundClass ||
        COLOR_KEYS?.yellow2?.backgroundClass
      } ${textColor} ${grid ? 'category-grid' : ''}  ${
        landing ? 'category-landing' : ''
      }`}
      href={href}
      onClick={() =>
        handleAnalytics(MATOMO_CATEGORY.VIEW_DETAIL, MATOMO_ACTION.VIEW_TOPIC)
      }
      aria-hidden="true"
    >
      <div className="category-title">
        {topic ? <div className="topic">{topic}</div> : null}
      </div>
    </Link>
  );
}

CategoryCard.Loading = function Loading() {
  return <CategoryCardLoading />;
};

export default CategoryCard;
