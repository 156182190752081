import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Tooltip } from 'antd';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import {
  FacebookShare,
  LinkedinShare,
  TelegramShare,
  TwitterShare,
  WhatsappShare
} from '../../../imageComponent/socialIcon';

function ShareSheet({ shareUrl, videos }) {
  const {
    state: { currentUser }
  } = useApp();

  const { trackEvent } = useMatomo();

  const handleShare = () => {
    trackEvent({
      category: MATOMO_CATEGORY.PODCAST,
      action: MATOMO_ACTION.PODCAST_SHARED,
      name: JSON.stringify({
        action: MATOMO_ACTION.PODCAST_SHARED,
        email: currentUser?.email ?? '',
        title: videos?.[0]?.title,
        id: videos?.[0]?.id,
        url: videos?.[0]?.url
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category: MATOMO_CATEGORY.PODCAST,
      action: MATOMO_ACTION.PODCAST_SHARED,
      name: JSON.stringify({
        action: MATOMO_ACTION.PODCAST_SHARED,
        email: currentUser?.email ?? '',
        title: videos?.[0]?.title,
        id: videos?.[0]?.id,
        url: videos?.[0]?.url
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <div className="d-flex flex-vertical">
      <div className="social-share-icon">
        <FacebookShareButton url={shareUrl} onClick={handleShare}>
          <FacebookShare />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} onClick={handleShare}>
          <TwitterShare />
        </TwitterShareButton>
        <TelegramShareButton url={shareUrl} onClick={handleShare}>
          <TelegramShare />
        </TelegramShareButton>
        <LinkedinShareButton url={shareUrl} onClick={handleShare}>
          <LinkedinShare />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} onClick={handleShare}>
          <WhatsappShare />
        </WhatsappShareButton>
        <InstapaperShareButton url={shareUrl} onClick={handleShare}>
          <InstapaperIcon size={48} round />
        </InstapaperShareButton>
        <EmailShareButton
          openShareDialogOnClick
          url={shareUrl}
          onClick={handleShare}
        >
          <EmailIcon size={48} round />
        </EmailShareButton>
      </div>
      <div className="share-item d-flex">
        <p>{shareUrl}</p>
        <CopyToClipboard
          // eslint-disable-next-line no-undef
          text={shareUrl}
        >
          <Tooltip
            title="Copied!"
            trigger="click"
            onClick={(e) => e?.stopPropagation()}
          >
            <Button className="cs-button" type="primary" onClick={handleShare}>
              Copy
            </Button>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default ShareSheet;
