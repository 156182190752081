import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Tag } from 'antd';
import Link from 'next/link';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';

function CollectionPillCard({ name, href = '#' }) {
  const {
    state: { currentUser }
  } = useApp();

  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        name
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        name
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <div
      onClick={() =>
        handleAnalytics(
          MATOMO_CATEGORY.COLLECTION,
          MATOMO_ACTION.COLLECTION_PILL_CARD_CLICKED
        )
      }
    >
      <Link prefetch={false} href={href}>
        <Tag className="collection-pill-carousel-tag">{name}</Tag>
      </Link>
    </div>
  );
}
export default CollectionPillCard;
