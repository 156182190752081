import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Typography } from 'antd';
import dynamic from 'next/dynamic';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import ActionButton from '../../../ActionButton';
import CtaCardLoading from '../../../skeleton/CtaCardLoading';

const BlurHashImage = dynamic(() => import('../../../BlurHashImage'), {
  loading: () => null,
  ssr: false
});

const { Title } = Typography;
function CtaCard({
  date,
  location,
  ctaMarketing = false,
  primaryAction,
  secondaryAction,
  contentlight,
  title,
  description,
  img,
  blurhash,
  background = 'bg-blue',
  instanceData
}) {
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();

  const handleAnalytics = (category, action) => {
    trackEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };
  return (
    <section>
      <div className="container-rnp">
        <div
          className={`ctacard  ${background} ${
            contentlight ? 'text-white-90' : 'text-n-1000'
          } ${ctaMarketing && `ctaMarketing`}`}
        >
          {img ? (
            <div className="ctaCard-img relative">
              <BlurHashImage
                src={img}
                blurhash={blurhash}
                fill
                alt={title}
                className="cover"
              />
            </div>
          ) : null}

          <div className={`ctacard-dec ${!img && 'only-dec'}`}>
            {title ? (
              <Title
                className={`${contentlight ? 'text-white-90' : 'text-n-1000'}`}
                level={3}
              >
                {title}
              </Title>
            ) : null}

            {date && (
              <div
                className={`date ${
                  contentlight ? 'text-white-90' : 'text-n-1000'
                }`}
              >
                {date}
              </div>
            )}
            {location && (
              <div
                className={`date ${
                  contentlight ? 'text-white-90' : 'text-n-1000'
                }`}
              >
                {location}
              </div>
            )}

            {description ? (
              <p
                className={`paragraph ${
                  contentlight ? 'text-white-90' : 'text-n-1000'
                }`}
              >
                {description}
              </p>
            ) : null}

            <div className="cta-button-wrapper">
              {secondaryAction?.title ? (
                <ActionButton
                  secondaryBtn
                  size="large"
                  type={secondaryAction?.type}
                  url={secondaryAction?.url}
                  internalPageType={secondaryAction?.internalPageType}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_BLOCK_SECONDARY_BTN_CLICKED
                    )
                  }
                  icon={secondaryAction?.asset}
                >
                  {secondaryAction?.title}
                </ActionButton>
              ) : null}
              {primaryAction?.title ? (
                <ActionButton
                  primaryBtn
                  size="large"
                  // buttonType="primary"
                  type={primaryAction?.type}
                  internalPageType={primaryAction?.internalPageType}
                  url={primaryAction?.url}
                  onClick={() =>
                    handleAnalytics(
                      MATOMO_CATEGORY.CTA,
                      MATOMO_ACTION.CTA_BLOCK_PRIMARY_BTN_CLICKED
                    )
                  }
                  icon={primaryAction?.asset}
                >
                  {primaryAction?.title}
                </ActionButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

CtaCard.Loading = function Loading() {
  return <CtaCardLoading />;
};

export default CtaCard;
