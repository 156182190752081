import { Col, Row } from 'antd';
import Link from 'next/link';
import React from 'react';

function ArticleListLoading() {
  const articleData = [1, 2, 3, 4, 5, 6];
  return (
    <section className="article-list-section skeleton-loader">
      <div className="container">
        <div className="section-header">
          <div className="heading skeleton" />
          <div className="paragraph skeleton" />
        </div>
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 32 },
            { xs: 16, sm: 16, md: 32, lg: 32 }
          ]}
        >
          {articleData.map((item, i) => (
            <Col
              // eslint-disable-next-line react/no-array-index-key
              key={`${item?.id}${i}`}
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Link href="/" className="article-list-card">
                <div className="article-card-img skeleton" />
                <div className="article-info">
                  <div className="badge skeleton" />
                  <p className="skeleton" />
                  <p className="skeleton" />
                  <p className="skeleton" />
                  <p className="skeleton" />
                </div>
              </Link>
            </Col>
          ))}
        </Row>
        <div className="article-view-btn text-center mt-20">
          <div className="button skeleton" />
        </div>
      </div>
    </section>
  );
}

export default ArticleListLoading;
